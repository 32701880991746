.video {
    @apply relative w-full;
    @apply ease-in duration-300;

    & .video-tools {
        @apply absolute flex opacity-0 pointer-events-none items-center bottom-0 w-full px-5 py-4;
        @apply ease-in duration-150;
        & .video-tools-left {
            @apply grid grid-cols-2 gap-5;
        }

        & .video-tools-right {
            @apply ml-auto grid grid-cols-2 gap-5;
        }
    }

    &:hover {
        .video-tools {
            @apply opacity-100 pointer-events-auto;
        }
    }
}
