.user-dropdown-handler {
    @apply flex items-center;

    & label {
        @apply mr-3 cursor-pointer;
    }
}

.user-dropdown {
    & button {
        @apply bg-transparent;
    }
}