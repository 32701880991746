.signin-page-app {
    @apply relative flex flex-col h-full bg-white;

    & .signin-page-body {
        @apply flex h-full;
    }

    & .signin-page-footer {
        @apply absolute bottom-0 w-full flex px-5 py-2 justify-end text-sm;

        & a {
            @apply font-semibold;
        }
    }
}