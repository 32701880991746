.signin-form-container {
    @apply flex justify-center items-center;
    @apply w-1/2 h-full p-5 bg-white;

    & .signin-form {
        @apply w-80;
    
        & h1 {
            @apply text-2xl font-bold mb-4;
        }
    
        & h2 {
            @apply text-sm text-gray-600 mb-8;
        }
    
        & h6 {
            @apply w-full text-xs text-center;
        }
    
        & a {
            @apply text-gray-500 text-xs;
            @apply font-semibold ml-auto;
        }
    
        & button {
            @apply mb-5 mt-10 w-full;
        }
    }
}