.bg-square {
    background-position: 5%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.signin-animation {
    @apply flex flex-col justify-center items-center h-full w-1/2 bg-gray-50;
    @extend .bg-square;

    & .cube-animation {
        width: 200px;
        height: 200px;
        margin-bottom: 10px;

        & svg {
            width: 250px;
            height: 250px;
        }
    }

    & h1 {
        @apply text-6xl font-black text-dark-chocolat;
        text-shadow: 10px 10px #6365f14f;
    }
}
