.signup-form-app {
    @apply w-80 p-5 bg-white rounded-xl;
}

.signup-form-password-recovery {
    @apply flex w-full text-xs;

    & a {
        @apply text-gray-500;
        @apply font-semibold ml-auto;
    }
}

.signup-form-register {

    @apply mt-4;

    & h6 {
        @apply w-full text-xs text-center;
    }

    & a {
        @apply text-gray-500;
        @apply font-semibold;
    }
}