.file-signed-download-card {
    @apply max-w-sm w-full bg-smokewhite-100 px-5 py-7 rounded-lg;

    & h6 {
        @apply font-semibold text-base;
    }

    & p {
        @apply text-sm leading-loose;
    }

    & table {
        @apply text-left border-none;

        & tr {
            @apply border-none;
        }

        & td {
            @apply px-2 align-top first:w-28 border-none;
        }
    }
}