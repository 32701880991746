.file-table-wrapper {
    @apply flex flex-col w-full h-full bg-white p-2;

    & .file-table-wrapper-body {
        @apply grow basis-0 overflow-auto;
    }

    & .file-table-wrapper-footer {
        @apply flex items-center py-2 px-3;

        & .file-table-wrapper-navigation {
            @apply flex items-center px-1 text-sm text-gray-900 bg-gray-100 border border-solid border-gray-200;

            & label {
                @apply mx-4;
            }

            & button {
                @apply p-0 w-4 h-8 #{!important};
            }
        }
    }
}
