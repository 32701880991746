.signin-locked {

    & h6 {
        @apply font-semibold mb-3;
    }

    & p {
        @apply text-sm mb-3;
    }

    & table {
        @apply text-sm mb-5;

        & td {
            @apply first:font-semibold;
        }
    }
}