.notification-list {
    @apply fixed flex top-16 bottom-0 w-80 z-20 transition-all duration-200 ease-in-out;
    @apply bg-smokewhite-100 border-solid border-l border-gray-200;

    & .notification-empty {
        @apply flex flex-col justify-center items-center m-auto;

        & h6 {
            @apply mt-5 text-sm text-gray-400;
        }
    }
}