@import "Font";

html {
    @apply h-full;
}

body {
    font-family: "Urbanist", sans-serif !important;
    font-weight: 500;
    @apply bg-gray-200;
    @apply h-full;
}

#root {
    @apply h-full;
}

form {
    & label {
        @apply text-sm font-bold text-gray-800;
    }
}

input[type="password"] {
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    &::placeholder {
        font-family: "Urbanist", sans-serif !important;
    }
}