.streaming-display {
    @apply w-full px-3;

    & h2 {
        @apply text-3xl font-semibold mt-3 mb-3;
    }

    & p {
        @apply text-gray-600;
    }
}