.streaming-details {
    @apply h-full;

    & .streaming-information-table {
        @apply mt-5 border-none #{!important};

        & tr {
            @apply border-x-0 #{!important};
            & td {
                @apply first:w-1/3;
            }
        }

        & thead {
            @apply sticky top-0 z-10;
        }

        & td,
        & th {
            @apply border-none #{!important};
        }
    }
}
