
@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Urbanist";
    src: url("/Assets/Font/Urbanist/Urbanist-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}