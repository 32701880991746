.uploader {

    & .uploader-dragdrop {

        @apply w-full flex flex-col justify-center items-center p-3;
        @apply bg-violet-100 rounded-md cursor-pointer;
        @apply text-center text-sm text-violet-500 border-dashed border border-violet-400;

        & .uploader-message {
            @apply mt-4 select-none;

            & b{
                @apply text-violet-600;
            }
        }

        & .uploader-logo {
            @apply block w-10 h-10 select-none bg-center bg-cover;
        }

        &.base {
            @apply h-16;
            @apply flex-row items-center;

            & .uploader-logo {
                @apply w-7 h-7 mr-2;
            }

            & .uploader-message {
                @apply mt-1;
            }
        }

        &.large {
            @apply h-44;
        }
    }

    & .drag-over {
        & .uploader-dragdrop {
            @apply bg-violet-200;
            @apply text-violet-600 border-dashed border border-violet-500;
        }
    }


}